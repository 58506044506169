import { Survey } from './types';
import { removeFromArray } from './utilities';

const removeSkipLogic = (
  sectionUUID: string,
  skipLogicUUID: string,
  survey: Survey,
) => {
  const section = { ...survey.sections[sectionUUID] };
  const skipLogics = { ...survey.skipLogics };
  const skipLogicConditions = { ...survey.skipLogicConditions };

  // Remove the skip logic ID from the section data
  const sectionSkipLogicIds = [...survey.sections[sectionUUID].skipLogicIds];
  const skipLogicIds = removeFromArray(sectionSkipLogicIds, skipLogicUUID);

  // Remove the skip logic conditions
  skipLogics[skipLogicUUID].conditionIds.forEach(
    (conditionId) => delete skipLogicConditions[conditionId],
  );

  // Remove the skip logic
  delete skipLogics[skipLogicUUID];

  return {
    sections: {
      ...survey.sections,
      [sectionUUID]: {
        ...section,
        skipLogicIds,
      },
    },
    skipLogics,
    skipLogicConditions,
  };
};

export const SkipLogicUtilities = {
  remove: removeSkipLogic,
};
