import { ParticipantStatuses } from 'lib/generated_constants/projects';

export const shouldHideIncentive = ({
  participantStatus,
  includeClientCanceledStatuses = true,
}) => {
  if (!participantStatus) return false;

  const participantStatusId =
    typeof participantStatus === 'number'
      ? participantStatus
      : ParticipantStatuses[participantStatus?.toUpperCase()];

  const excludedStatuses = [
    ParticipantStatuses.NO_SHOW,
    ParticipantStatuses.FRAUDULENT,
    ParticipantStatuses.PARTICIPANT_CANCELED,
    ParticipantStatuses.PARTICIPANT_RESCHEDULED,
  ];

  if (!includeClientCanceledStatuses) {
    return (
      participantStatusId && excludedStatuses.includes(participantStatusId)
    );
  }

  return (
    participantStatusId &&
    [
      ...excludedStatuses,
      ParticipantStatuses.RESEARCHER_CANCELED,
      ParticipantStatuses.RESEARCHER_RESCHEDULED,
    ].includes(participantStatusId)
  );
};
