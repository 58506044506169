import generateUUID from 'lib/generate_uuid';

import { SurveyBuilderConstants as Constants } from 'components/survey_builder';

import { SkipLogicConditionUtilities } from './skip_logic_condition_utilities';

import { Question, QuestionGroup, QuestionType, Survey } from './types';

import {
  buildAnswersForQuestion,
  buildQuestion,
  insertIntoArray,
  isBooleanType,
  isPickNoneType,
  isPickOneOrAnyType,
  isPickOneOrAnyGridType,
  isQuestionGroup,
  removeFromArray,
  updateGridToGridQuestionType,
  updateGridQuestionType,
  updatePickQuestionType,
  updateNoPickToNoPickQuestionType,
  updatePickToPickQuestionType,
  updateNoPickQuestionType,
  removeQuestionAndAnswers,
} from './utilities';

const addQuestion = (
  sectionUUID: string,
  prevQuestionUUID: string,
  survey: Survey,
) => {
  const newQuestion = buildQuestion();
  const questions = { ...survey.questions };
  Object.keys(questions).forEach((uuid) => {
    questions[uuid] = { ...questions[uuid], isNew: false };
  });

  const section = { ...survey.sections[sectionUUID] };
  section.questionIds = insertIntoArray(
    section.questionIds,
    newQuestion.uuid,
    prevQuestionUUID,
  );

  const newAnswers = buildAnswersForQuestion(newQuestion);
  const answers = { ...survey.answers };
  newAnswers.forEach((answer) => {
    answers[answer.uuid] = answer;
    newQuestion.answerIds.push(answer.uuid);
  });

  return {
    answers,
    questions: { ...questions, [newQuestion.uuid]: newQuestion },
    sections: { ...survey.sections, [section.uuid]: section },
  };
};

// Moved without change from SurveyBuilder
// TODO: Improve/move this to where it's more applicable
const determineQuestionType = (question: Question, survey: Survey) => {
  if (question.pick === Constants.QUESTION_PICK_ONE) {
    if (isQuestionGroup(question)) return Constants.QUESTION_TYPE_PICK_ONE_GRID;

    return Constants.QUESTION_TYPE_PICK_ONE;
  }

  if (question.pick === Constants.QUESTION_PICK_ANY) {
    if (isQuestionGroup(question)) return Constants.QUESTION_TYPE_PICK_ANY_GRID;

    return Constants.QUESTION_TYPE_PICK_ANY;
  }

  if (question.pick === Constants.QUESTION_BOOLEAN) {
    return Constants.QUESTION_TYPE_BOOLEAN;
  }

  if (question.pick === Constants.QUESTION_PICK_NONE) {
    if (question.answerIds.length) {
      // If we have answers, refer to the first (and only) answer to determine the question type
      const answer = survey.answers[question.answerIds[0]];

      if (answer.responseClass === Constants.RESPONSE_CLASS_DATE) {
        return Constants.QUESTION_TYPE_DATE;
      }

      if (answer.responseClass === Constants.RESPONSE_CLASS_FLOAT) {
        return Constants.QUESTION_TYPE_FLOAT;
      }

      return answer.responseClass === Constants.RESPONSE_CLASS_TEXT
        ? Constants.QUESTION_TYPE_TEXT
        : Constants.QUESTION_TYPE_STRING;
    }

    // If there are no answers associated w/ this question, default to STRING type
    return Constants.QUESTION_TYPE_STRING;
  }

  throw TypeError(
    `Unexpected question pick found when determining question type: ${question.pick}`,
  );
};

const duplicateQuestion = (
  sectionUUID: string,
  questionUUID: string,
  survey: Survey,
) => {
  const section = { ...survey.sections[sectionUUID] };
  const newQuestion = {
    ...survey.questions[questionUUID],
    errors: {},
    isEditing: false,
    uuid: generateUUID(),
  } as Question | QuestionGroup;
  delete newQuestion.participantPopulationAttributeId;
  const answers = { ...survey.answers };
  const questions = { ...survey.questions };

  if (isQuestionGroup(newQuestion)) {
    // Copy all the question's questions and put them into the new question map
    newQuestion.questionIds = newQuestion.questionIds.map((questionId) => {
      const newQuestionGroupQuestion = {
        ...survey.questions[questionId],
        errors: {},
        questionGroupId: newQuestion.uuid,
        uuid: generateUUID(),
      } as Question;

      // Copy all the question group question's answers and put them into the new answers map
      newQuestionGroupQuestion.answerIds =
        newQuestionGroupQuestion.answerIds.map((answerId) => {
          const newAnswer = {
            ...survey.answers[answerId],
            errors: {},
            uuid: generateUUID(),
          };
          answers[newAnswer.uuid] = newAnswer;

          return newAnswer.uuid;
        });

      questions[newQuestionGroupQuestion.uuid] = newQuestionGroupQuestion;

      return newQuestionGroupQuestion.uuid;
    });
  } else {
    // Copy all the question's answers and put them into the new answers map
    newQuestion.answerIds = newQuestion.answerIds.map((answerId) => {
      const newAnswer = {
        ...survey.answers[answerId],
        errors: {},
        uuid: generateUUID(),
      };
      answers[newAnswer.uuid] = newAnswer;

      return newAnswer.uuid;
    });
  }

  // Insert the question after the previous question
  section.questionIds = insertIntoArray(
    section.questionIds,
    newQuestion.uuid,
    questionUUID,
  );

  return {
    answers,
    questions: { ...questions, [newQuestion.uuid]: newQuestion },
    sections: { ...survey.sections, [section.uuid]: section },
  };
};

const moveQuestionWithinSection = (
  { droppableId, index }: { droppableId: string; index: number },
  questionId: string,
  survey: Survey,
) => {
  const questionIds = [...survey.sections[droppableId].questionIds];
  const currentPosition = questionIds.indexOf(questionId);
  if (currentPosition === index) return survey;

  questionIds.splice(currentPosition, 1);
  questionIds.splice(index, 0, questionId);

  return {
    sections: {
      ...survey.sections,
      [droppableId]: {
        ...survey.sections[droppableId],
        questionIds,
      },
    },
  };
};

const removeQuestion = (
  sectionUUID: string,
  questionUUID: string,
  survey: Survey,
) => {
  if (survey.sections[sectionUUID].questionIds.length === 1)
    return { ...survey };

  let updatedSurvey = { ...survey };
  let questions = { ...survey.questions };
  let answers = { ...survey.answers };
  const updatedSections = { ...survey.sections };
  const updatedSection = { ...survey.sections[sectionUUID] };

  // Delete question as well as answers or nested questions and answers
  ({ answers, questions } = removeQuestionAndAnswers(
    questions[questionUUID],
    updatedSurvey,
  ));

  // Delete question id from section question ids
  updatedSection.questionIds = removeFromArray(
    updatedSection.questionIds,
    questionUUID,
  );

  updatedSurvey = {
    ...updatedSurvey,
    answers,
    questions,
    sections: { ...updatedSections, [sectionUUID]: updatedSection },
  };

  // Delete skip logic conditions
  const skipLogicUpdates = SkipLogicConditionUtilities.removeQuestionConditions(
    sectionUUID,
    questionUUID,
    updatedSurvey,
  );

  return {
    ...updatedSurvey,
    ...skipLogicUpdates,
  };
};

const updateQuestion = (
  questionUUID: string,
  updates: Question,
  survey: Survey,
) => {
  const question = {
    ...survey.questions[questionUUID],
    ...updates,
    errors: {},
  };

  return {
    questions: { ...survey.questions, [question.uuid]: question },
  };
};

const updateQuestionType = (
  questionUUID: string,
  questionType: QuestionType,
  survey: Survey,
) => {
  const question = survey.questions[questionUUID] as Question;

  const prevQuestionType = determineQuestionType(question, survey);

  if (questionType === prevQuestionType) return {};

  let update;

  if (isPickNoneType(questionType)) {
    if (isPickNoneType(prevQuestionType)) {
      update = updateNoPickToNoPickQuestionType(
        questionUUID,
        questionType,
        survey,
      );
    } else {
      update = updateNoPickQuestionType(questionUUID, questionType, survey);
    }
  }

  if (isPickOneOrAnyType(questionType)) {
    if (isPickOneOrAnyType(prevQuestionType)) {
      update = updatePickToPickQuestionType(questionUUID, questionType, survey);
    } else {
      update = updatePickQuestionType(questionUUID, questionType, survey);
    }
  }

  if (isBooleanType(questionType)) {
    update = updatePickQuestionType(questionUUID, questionType, survey);
  }

  if (isPickOneOrAnyGridType(questionType)) {
    if (isPickOneOrAnyGridType(prevQuestionType)) {
      update = updateGridToGridQuestionType(questionUUID, questionType, survey);
    } else {
      update = updateGridQuestionType(questionUUID, questionType, survey);
    }
  }

  if (!update) return {};
  // TODO Be smarter about this but at least we can pull this out of the child component.
  // We should be looking at the prev / next question type to ensure we only unset this if
  // it does not apply to the new type (e.g. we moved from Decimal to Boolean). Ideally
  // we'd have standard logic that looks at the attribute types / question types and does
  // that for us.
  update.questions[questionUUID].participantPopulationAttributeId = undefined;

  return {
    answers: update.answers,
    questions: update.questions,
  };
};

export const QuestionUtilities = {
  add: addQuestion,
  determineType: determineQuestionType,
  duplicate: duplicateQuestion,
  // move: moveQuestionFromSection, skipLogic tie in here
  moveWithinSection: moveQuestionWithinSection,
  remove: removeQuestion,
  update: updateQuestion,
  updateType: updateQuestionType,
};
