import { ENV } from 'lib/react_on_rails/env';

import { Status } from './constants/http';
import { toSentenceCase } from './formatters/strings';

function defaultErrorString(actionText = '') {
  return `There was an unexpected error${actionText ? ` ${actionText}` : ''}, please try again later`;
}

function compileAlertErrors(error, actionText = '', options = {}) {
  const { delimiter = '\n\n', messagePrefix = 'There were errors' } = options;

  const fieldErrors = error.fieldErrors();

  let message;
  if (Object.keys(fieldErrors).length) {
    const warnings = Object.values(fieldErrors).reduce(
      (total, current) => [...total, ...current],
      [],
    );

    message = `${messagePrefix}${actionText ? ` ${actionText}` : ''}:\n\n${warnings.join(delimiter)}`;
  } else if (error.status === Status.TOO_MANY_REQUESTS) {
    message =
      'You have made too many attempts, please wait and try again later.';
  } else if (error.status === Status.UNAUTHORIZED) {
    message = 'You are not authorized to take this action';
  } else {
    message = defaultErrorString(actionText);
  }

  return message;
}

export function parseFriendlyErrors(error) {
  const friendlyErrors = error?.error?.data?.meta?.friendlyErrors || [];
  const errorMessages = [];
  friendlyErrors.map((errorObj) =>
    errorObj?.humanErrors.forEach((humanError) => {
      if (typeof humanError === 'string') {
        errorMessages.push(humanError);
      } else {
        const errorKey = Object.keys(humanError)[0];
        const errorValue = humanError[errorKey].join(', ');
        errorMessages.push(`${errorKey} ${errorValue}`);
      }
      return null;
    }),
  );
  if (errorMessages.length === 0) {
    return 'There was an unexpected error, please try again later';
  }
  return toSentenceCase(errorMessages.join(', '));
}

class UiError extends Error {
  get isValidationError() {
    return false;
  }

  get rawErrors() {
    // this should return whatever raw blob the API might have attached to it... ideally this DIAF
    return null;
  }

  apiErrors() {
    // this should return API related errors { field_key: errors[] } that are unlikely user-friendly
    return {};
  }

  fieldErrors() {
    // this should return a hash of human-facing { field_key: errors[] }
    return {};
  }
}

export class DisplayableError extends UiError {
  constructor(message) {
    super();

    this.message = message;
  }
}

export class HttpError extends UiError {
  constructor({
    cause = null,
    content = {},
    errors = content?.errors,
    message,
    meta = content?.meta,
    status,
    statusText,
  } = {}) {
    super(
      message || 'An unexpected error has occurred, please try again later',
      { cause },
    );

    this._errors = errors || (content?.data || {}).errors || [];
    this._friendlyErrors = (meta || {}).friendlyErrors || [];
    this._meta = meta || {};

    this.content = content; // TODO Rename raw response?
    this.status = status;
    this.statusText = statusText;
  }

  get isValidationError() {
    return this.status === Status.UNPROCESSABLE_ENTITY;
  }

  get rawErrors() {
    return this._errors;
  }

  apiErrors() {
    return this._getJsonApiErrors();
  }

  fieldErrors() {
    const friendlyApiErrors = this._getFriendlyApiErrors();

    if (Object.keys(friendlyApiErrors).length) {
      // Prioritize friendly API first...
      return friendlyApiErrors;
    }

    if (this.rawErrors instanceof Array) {
      // then deal with our general nightmare
      return this._getFieldErrorsFromArray(this.rawErrors);
    }

    if (typeof this.rawErrors !== 'object') {
      // ignore any other thing that might have come in
      return {};
    }

    // well... lets hope this is correct by now
    return this.rawErrors || {};
  }

  _getJsonApiErrors() {
    // JSON API errors are an array of
    // { source: { pointer: '/data/attributes/name' }, detail: 'cannot be nil' },
    if (
      !(this._errors instanceof Array) ||
      !this._errors.length ||
      !this._errors[0].source
    ) {
      // errors often come down with a mixture of types
      return {};
    }

    return this._errors.reduce((map, error) => {
      const { source, detail } = error;
      const values = map[source.pointer] || [];
      values.push(detail);

      return { ...map, [source.pointer]: values };
    }, {});
  }

  _getFriendlyApiErrors() {
    return this._friendlyErrors.reduce((errors, e) => {
      const values = (errors[e.field] || []).concat(e.humanErrors);

      return {
        ...errors,
        [e.field]: values,
      };
    }, {});
  }

  _getFieldErrorsFromArray(errors) {
    if (!errors.length || typeof errors[0] === 'string') {
      // don't know how to turn this into a KVP of some kind
      return {};
    }

    // reduce an array of KVP into a normal hash
    return errors.reduce((total, errorObj) => {
      const newMerge = Object.entries(errorObj).reduce((merge, kvp) => {
        const [key, value] = kvp;
        const values = total[key] || [];
        values.push(value);

        return {
          ...merge,
          [key]: values,
        };
      }, {});

      // merge is safe to override b/c building it includes entries in total
      return {
        ...total,
        ...newMerge,
      };
    }, {});
  }
}

export class ErrorRenderer {
  // TODO UI-2998 -- not sure if this belongs in "common" if this became a react component
  static alert(error, actionText = '', options = {}) {
    if (ENV?.DEBUG_JAVASCRIPT_ERRORS) {
      window.console.log(error);
    }
    const message = ErrorRenderer.compileAlertMessage(
      error,
      actionText,
      options,
    );
    window.alert(message);
  }

  // Adding this, though once RTK Query endpoint implementations replace our useHttp ones,
  // then we can have the compileAlertMessage function have this logic as well and just use that
  static compileRtkQueryErrorMessage(error, actionText = '') {
    const httpError = new HttpError({
      content: error.data,
      status: error.status,
    });

    return this.compileAlertMessage(httpError, actionText);
  }

  static compileAlertMessage(error, actionText = '', options = {}) {
    let message;

    if (error instanceof DisplayableError) {
      ({ message } = error);
    } else if (error instanceof UiError) {
      message = compileAlertErrors(error, actionText, options);
    } else {
      message = actionText ? defaultErrorString(actionText) : error.message;
    }

    return message;
  }

  static compileFieldErrors(error) {
    const httpError = new HttpError({
      content: error.data,
      status: error.status,
    });

    return httpError.fieldErrors();
  }
}

export default UiError;
