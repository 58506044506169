import pluralize from 'pluralize';
import {
  format,
  formatDistanceToNow,
  parseISO,
  secondsToMinutes,
  set,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import {
  DATE_AND_TIME_WITH_MONTH_FORMAT,
  DATE_FNS_FORMAT_SHORT,
  DATE_FNS_FORMAT_FULL_YEAR,
  DAY_AND_DATE_FORMAT_FNS,
  DAY_AND_DATE_FORMAT_FNS_SHORT,
  TIME_FNS_FORMAT_SHORT,
  TIME_FNS_FORMAT_SHORT_ALL_CAPS,
  TIME_WITH_ZONE_FORMAT_SHORT,
  STANDARD_TIME_FORMAT_WITH_TZ_FNS,
  ISO_DATE_FORMAT_FNS,
} from 'lib/formatters/constants';

export function formatStudyLengthToMinutes(studyLength) {
  const minutesInAnHour = 60;
  const minutesInADay = 24 * minutesInAnHour;
  const minutesInAWeek = 7 * minutesInADay;

  let totalMinutes = 0;

  if (studyLength.minutes) {
    totalMinutes += studyLength.minutes;
  }

  if (studyLength.hours) {
    totalMinutes += studyLength.hours * minutesInAnHour;
  }
  // This is really not possible since we don't save days / weeks
  // for unmoderated, one on one, focus groups. But it's very trivial
  // to cover this case so adding it here
  if (studyLength.days) {
    totalMinutes += studyLength.days * minutesInADay;
  }

  // Same here
  if (studyLength.weeks) {
    totalMinutes += studyLength.weeks * minutesInAWeek;
  }

  return totalMinutes;
}

// Multi day studies in the old world can have assigned weeks and days only from the builder
export function formatStudyLengthToDays(studyLength) {
  const daysInAWeek = 7;

  let totalDays = 0;

  if (studyLength.days) {
    totalDays += studyLength.days;
  }

  if (studyLength.weeks) {
    totalDays += studyLength.weeks * daysInAWeek;
  }

  return totalDays;
}

export function formatMultiDayRange(start, end) {
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  const startDate = new Date(start).toLocaleString('en-US', options);

  if (end) {
    const endDate = new Date(end).toLocaleString('en-US', options);
    return `${startDate} - ${endDate}`;
  }

  return startDate;
}

export function formatDeadlineRange(start, timeZone) {
  const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
    timeZoneName: 'short',
  };

  const deadlineString = new Date(start).toLocaleTimeString('en-US', options);

  return deadlineString;
}

export function formatRangeDisplayText(start, end, timeZone) {
  const endOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
    timeZoneName: 'short',
  };

  const startOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
  };

  const startString = new Date(start).toLocaleTimeString('en-US', startOptions);
  const endString = new Date(end).toLocaleTimeString('en-US', endOptions);

  return `${startString} - ${endString}`;
}

export function formatRangeConfirmText(start, end, timeZone) {
  const endOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
    timeZoneName: 'short',
  };

  const startOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
  };

  const startString = new Date(start).toLocaleTimeString('en-US', startOptions);
  const endString = new Date(end).toLocaleTimeString('en-US', endOptions);

  return `${startString} - ${endString}`;
}

export function formatRangeDateInWords(startDate) {
  const options = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  return new Date(startDate).toLocaleString('en-US', options);
}

export function formatTimeSinceWithTime(dateTimeString) {
  const dateTime = parseISO(dateTimeString);
  const timeAgo = formatDistanceToNow(dateTime, { addSuffix: true });

  return `${timeAgo} at ${format(dateTime, TIME_FNS_FORMAT_SHORT)}`;
}

export function formatDateFromString(dateString) {
  // This is annoyingly bonkers but if you do new Date it will assume local timezone and break.
  // So build the date, then use the UTC components to build a new date and format!
  const utcDate = new Date(dateString);
  const localDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
  );

  return localDate.toLocaleDateString();
}

export function formatShortTimeFromString(dateString) {
  return format(parseISO(dateString), TIME_FNS_FORMAT_SHORT);
}

export function formatShortTimeAllCapsFromString(dateString) {
  return format(parseISO(dateString), TIME_FNS_FORMAT_SHORT_ALL_CAPS);
}

export function formatShortDateFromString(dateString) {
  return format(parseISO(dateString), DATE_FNS_FORMAT_SHORT);
}

export function formatStandardDateFromString(dateString) {
  return format(parseISO(dateString), ISO_DATE_FORMAT_FNS);
}

export function formatFullYearDateFromString(dateString) {
  return format(parseISO(dateString), DATE_FNS_FORMAT_FULL_YEAR);
}

export function formatTimeWithZoneFromString(dateString, timeZone) {
  const dateTime = parseISO(dateString);
  return formatInTimeZone(dateTime, timeZone, STANDARD_TIME_FORMAT_WITH_TZ_FNS);
}

// Returns a formatted string representing the date in the given timezone.
// In this example the correct date is the prev day in the given TZ --
// formatDateInZoneFromString("2024-06-18T00:00:00.000Z", "America/New_York") => "Mon, Jun 17th"
export function formatDateInZoneFromString(dateString, timeZone) {
  const dateTime = parseISO(dateString);
  return formatInTimeZone(dateTime, timeZone, DAY_AND_DATE_FORMAT_FNS_SHORT);
}

// Returns a formatted string representing the range of dates with the timezone
// ie "11:45am-12:45pm EDT"
export function formatRangeWithZoneFromString(
  startDateString,
  endDateString,
  timeZone,
) {
  const startDateTime = parseISO(startDateString);
  const endDateTime = parseISO(endDateString);

  const formattedStart = formatInTimeZone(
    startDateTime,
    timeZone,
    TIME_FNS_FORMAT_SHORT,
  );
  const formattedEnd = formatInTimeZone(
    endDateTime,
    timeZone,
    TIME_FNS_FORMAT_SHORT,
  );
  const formattedZone = formatInTimeZone(startDateTime, timeZone, 'z');
  return `${formattedStart}-${formattedEnd} ${formattedZone}`;
}

export function formatTimeWithZone(date, timeZone) {
  return formatInTimeZone(date, timeZone, STANDARD_TIME_FORMAT_WITH_TZ_FNS);
}

export function formatDateWithTimeFromString(dateString) {
  const dateTime = parseISO(dateString);

  return format(
    dateTime,
    `${DATE_FNS_FORMAT_SHORT} '@' ${TIME_FNS_FORMAT_SHORT}`,
  );
}

export function formatFullDateWithTimeAllCapsFromString(dateString) {
  const fullYearDate = formatFullYearDateFromString(dateString);
  const timeInCaps = formatShortTimeAllCapsFromString(dateString);

  return `${fullYearDate} at ${timeInCaps}`;
}

export function formatFullMonthDayYear(datestring) {
  return new Date(datestring).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatDateWithTimeWithLocalZoneFromString(dateString) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return formatInTimeZone(dateString, timeZone, TIME_WITH_ZONE_FORMAT_SHORT);
}

export function formatDateFromIso8601(dateTime) {
  if (dateTime) {
    return new Date(dateTime).toLocaleDateString();
  }

  return null;
}

export function formatDateTimeFromIso8601(dateTime, options = {}) {
  if (dateTime) {
    return new Date(dateTime).toLocaleString('en-US', options);
  }

  return null;
}

export function formatDateAndTimeWithAbbrevMonth(dateTime) {
  return format(parseISO(dateTime), DATE_AND_TIME_WITH_MONTH_FORMAT);
}

export function formatDayAndDateFromString(dateString) {
  const dateTime = parseISO(dateString);
  return format(dateTime, DAY_AND_DATE_FORMAT_FNS);
}

export function formatDurationFromSeconds(timeInSeconds, short = false) {
  if (timeInSeconds < 60) {
    if (short) return '< 1 min';

    return 'Less than a minute';
  }

  if (timeInSeconds > 7200) {
    return 'Over 2 hours';
  }

  return pluralize('minute', secondsToMinutes(timeInSeconds), true);
}

export function formatDaysSince(daysSince) {
  if (daysSince === null) {
    // JSX is stupid and won't let you return a string with an HTML encoded string...
    // This is &mdash
    return '\u2014';
  }

  if (daysSince === 0) {
    return 'Today';
  }

  return `${pluralize('day', daysSince, true)} ago`;
}

export function daysSinceToday(dateTime) {
  if (!dateTime) {
    return null;
  }

  const timeConversionConst = 1000 * 60 * 60 * 24;
  const startDate = new Date(dateTime);
  const endDate = new Date();
  return Math.floor(
    (endDate.getTime() - startDate.getTime()) / timeConversionConst,
  );
}

export function formatDateFromIso8601WithDaysSince(dateTime) {
  if (!dateTime) {
    return null;
  }

  return `${formatDateFromIso8601(dateTime)} (${formatDaysSince(daysSinceToday(dateTime))})`;
}

export function getParticipantDobRange() {
  const currentYear = new Date().getFullYear();
  return {
    minDate: set(new Date(), { year: currentYear - 100 }),
    maxDate: set(new Date(), { year: currentYear - 16 }),
  };
}
