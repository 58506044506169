import Appsignal from '@appsignal/javascript';
import { type JSSpan } from '@appsignal/types';

import { plugin as networkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as pathPlugin } from '@appsignal/plugin-path-decorator';
import { plugin as windowPlugin } from '@appsignal/plugin-window-events';

import { ENV } from 'lib/react_on_rails/env';
import { extractUriDetails } from './extract_uri_details';
import { ignoreErrors } from './ignore_errors';
import QueryString from '../query_string';

export const DEFAULT_NAMESPACE = 'frontend';

export function initializeAppsignal({
  namespace,
  env,
}: {
  namespace: string;
  env?: typeof ENV;
}) {
  if (!env || !env.APPSIGNAL_ENABLED) return null;

  const finalNamespace = namespace.includes(DEFAULT_NAMESPACE)
    ? namespace
    : `${namespace}_${DEFAULT_NAMESPACE}`;

  const appsignal = new Appsignal({
    key: env.APPSIGNAL_JS_API_KEY,
    namespace: finalNamespace,
    revision: env.APP_COMMIT ?? undefined,
    ignoreErrors: env.APPSIGNAL_JS_IGNORE_ENABLED ? ignoreErrors : [],
  });

  appsignal.addDecorator((span: JSSpan) => {
    const uriDetails = extractUriDetails(window.location.pathname);
    span.setAction(uriDetails.action);

    if (Object.keys(uriDetails.params).length) {
      span.setParams(uriDetails.params);
    }

    if (window.location.search) {
      span.setParams(QueryString.parse(window.location.search));
    }

    return span;
  });

  appsignal.addDecorator((span: JSSpan) => {
    const tags: { team?: string; user?: string } = {};

    if (env.TELEMETRY_USER?.teamId) {
      tags.team = env.TELEMETRY_USER.teamId.toString();
    }
    if (env.TELEMETRY_USER?.id) {
      tags.user = env.TELEMETRY_USER.id.toString();
    }

    return span.setTags(tags);
  });

  return appsignal;
}

function initializeDefaultAppsignal() {
  if (!ENV) return;

  const appsignal = initializeAppsignal({
    namespace: 'frontend',
    env: ENV,
  });

  if (!appsignal) return;

  const plugins = [networkPlugin, pathPlugin];

  if (ENV.APPSIGNAL_JS_WINDOW_EVENTS) {
    plugins.push(windowPlugin);
  }

  // May want to consider `xhrEnabled: false` option for the networkPlugin
  // as this seems to mostly capture third party scripts vs. internal fetch
  plugins.forEach((plugin) => appsignal.use(plugin()));

  return appsignal;
}

// eslint-disable-next-line import/no-default-export
export default initializeDefaultAppsignal();
