import { toUnderscoreCase } from './strings';

export const snakeCaseAllKeys = (obj) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const underScoreKey = toUnderscoreCase(key);

    if (value instanceof Object) {
      result[underScoreKey] = snakeCaseAllKeys(value);
    } else if (value instanceof Array) {
      result[underScoreKey] = value.map((_key) => snakeCaseAllKeys(_key));
    } else {
      result[underScoreKey] = value;
    }
  });

  return result;
};

export const nullifyWhitespaceAttributes = (attributes) => {
  const parsedAttributes = {};

  Object.keys(attributes).forEach((k) => {
    parsedAttributes[k] =
      typeof attributes[k] === 'string' && attributes[k]?.trim() === ''
        ? null
        : attributes[k];
  });

  return parsedAttributes;
};
