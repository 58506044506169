/* eslint-disable max-classes-per-file */
import {
  PROJECT_PARTICIPANT_STATUS_RUBY_ENUM,
  PROJECT_PARTICIPANT_EMAILABLE_STATUS_RUBY_ENUM,
} from 'lib/generated_constants/projects';
import { Enum, EnumValue } from '../constants/enums';

export class ProjectParticipantStatus extends EnumValue {}
class ProjectParticipantStatusEnum extends Enum {
  constructor() {
    super(PROJECT_PARTICIPANT_STATUS_RUBY_ENUM, ProjectParticipantStatus);
  }
}

class ProjectParticipantEmailableStatusEnum extends Enum {
  constructor() {
    super(
      PROJECT_PARTICIPANT_EMAILABLE_STATUS_RUBY_ENUM,
      ProjectParticipantStatus,
    );
  }
}

export const ProjectParticipantStatuses = new ProjectParticipantStatusEnum();

export const ProjectParticipantEmailableStatuses =
  new ProjectParticipantEmailableStatusEnum();
