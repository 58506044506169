export function arrayToLookup(
  array,
  keySelector,
  valueSelector = (value) => value,
) {
  const lookup = {};

  array.forEach((value) => {
    lookup[keySelector(value)] = valueSelector(value);
  });

  return lookup;
}

export function arrayToChunks(array, chunkSize) {
  const chunks = [];
  let currentChunk = [];

  chunks.push(currentChunk);

  array.forEach((value) => {
    if (currentChunk.length === chunkSize) {
      currentChunk = [];
      chunks.push(currentChunk);
    }

    currentChunk.push(value);
  });

  return chunks;
}

export const recordsByTeamName = (records) =>
  recordsByDig(records, 'My Team', 'team', 'name');

export const recordsByDig = (records, defaultLabel, ...keys) =>
  records.reduce((acc, record) => {
    const result = dig(record, keys, defaultLabel);

    if (!acc[result]) {
      acc[result] = [];
    }

    acc[result].push(record);

    return acc;
  }, {});

const dig = (obj, keys, defaultLabel = null) => {
  if (typeof obj !== 'object' || obj === null || keys.length === 0) {
    return obj || defaultLabel;
  }

  const newObj = obj[keys[0]];
  const newKeys = [...keys];

  newKeys.shift();

  return dig(newObj, newKeys, defaultLabel);
};

export function isSubset(subset, superset) {
  const allItems = new Set([...superset]);
  const subsetItems = [...subset];

  return subsetItems.every((item) => allItems.has(item));
}

export function isEquivalent(set1, set2) {
  const set1Size = set1.length || set1.size;
  const set2Size = set2.length || set2.size;

  return set1Size === set2Size && isSubset(set1, set2);
}

export function objectCompact(object) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
}

export function sortByStringKey(array, key) {
  array.sort((a, b) => {
    const aString = a[key] || '';
    const bString = b[key] || '';

    if (aString < bString) {
      return -1;
    }

    if (aString > bString) {
      return 1;
    }

    return 0;
  });

  return array;
}

export function removeArrayItem(array, item) {
  const index = array.indexOf(item);

  return index > -1 ? array.splice(index, 1) : array;
}
