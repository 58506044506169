/*
Wrappers for fetch() that automatically configure appropriate headers and transform the response
into JSON for us because any other content type is the devil's work, as well as for QueryString
that configure parsing and such to presume the Rails bracket array format
 */

import { JsonApiDataStore } from 'jsonapi-datastore';

// eslint-disable-next-line no-restricted-imports
import { getCSRFToken } from 'lib/csrf';
import { Status } from './constants/http';
import { HttpError } from './errors';
import { logError } from './logging';
import QueryString from './query_string';

const APPLICATION_JSON = 'application/json';

// Because JsonApiDataStore doesn't support metadata on an individual object level
// we have to shoehorn it into the attributes of each so that we can still use it
// without having to overhaul everything.
class JsonApiDataStoreWithObjectMeta extends JsonApiDataStore {
  sync(response) {
    this.shoehornMetaForObjects(response);
    return super.sync(response);
  }

  syncWithMeta(response) {
    this.shoehornMetaForObjects(response);
    return super.syncWithMeta(response);
  }

  shoehornMetaForObjects(response) {
    const { data, included } = response;
    [data, included].forEach(this.shoehornMetaIntoAttrs);
  }

  shoehornMetaIntoAttrs(collection) {
    if (!collection) return;

    const collectionArray =
      collection instanceof Array ? collection : [collection];
    collectionArray.forEach((obj) => {
      if (obj.meta) {
        const { attributes } = obj;
        attributes.meta = obj.meta;
      }
    });
  }
}

const FormatResponse = {
  jsonApi:
    ({ includeMeta }) =>
    (response) => {
      if (includeMeta) {
        return new JsonApiDataStoreWithObjectMeta().syncWithMeta(response);
      }

      return new JsonApiDataStoreWithObjectMeta().sync(response);
    },

  raw: (response) => response,
};

function addCSRFSettings(request) {
  const csrfRequest = {
    ...request,
    headers: {
      'X-Requested-With': 'XMLHTTPRequest',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'same-origin',
  };

  if (request.headers) {
    csrfRequest.headers = {
      ...request.headers,
      ...csrfRequest.headers,
    };
  }

  return csrfRequest;
}

function buildRequest(method, body) {
  const request = {
    headers: {
      Accept: APPLICATION_JSON,
    },
    cache: 'default',
    method,
  };

  if (body) {
    if (body instanceof FormData) {
      request.body = body;
    } else {
      request.body = JSON.stringify(body);
      request.headers['Content-Type'] = APPLICATION_JSON;
    }
  }

  return addCSRFSettings(request);
}

async function callFetch(path, request) {
  let error;

  try {
    const response = await fetch(path, request);

    if (response.status === Status.NO_CONTENT) {
      return null;
    }

    if (response.ok) {
      return response.json();
    }

    const errorParams = {
      status: response.status,
      statusText: response.statusText,
    };

    if (response.status === Status.SERVICE_UNAVAILABLE) {
      errorParams.message = `The server timed out and we were unable to complete your request. We're\
 looking into the issue but feel free to try again.`;
    } else if (response.status === Status.TOO_MANY_REQUESTS) {
      errorParams.message =
        'You have made too many attempts, please wait and try again later.';
    } else if (response.status === Status.UNAUTHORIZED) {
      errorParams.message = 'You are not authorized to take this action';
    } else if (
      response.headers.get('Content-Type') &&
      response.headers.get('Content-Type').indexOf(APPLICATION_JSON) > -1
    ) {
      errorParams.content = await response.json();
    }

    error = new HttpError(errorParams);
  } catch (err) {
    // If we are catching here, there's something else going wrong we are likely unsure of but
    // clients of fetch should still get an HttpError back for error handling reasons
    logError(err);

    error = new HttpError({
      cause: err,
      content: err,
      status: Status.UNKNOWN,
      statusText: err.message,
    });
  }

  if (error) {
    throw error;
  }

  return null;
}

function get(path, params = {}) {
  // Only process params if we are passed something. Otherwise assume the path is complete.
  // This is helpful because QueryString is not always great at processing params while
  // JSRoutes is pretty good at building URLs for ingestion by Rails.
  if (Object.keys(params).length) {
    const { url, query } = QueryString.parseUrl(path);

    let queryParams;
    if (params instanceof FormData) {
      queryParams = Array.from(params.keys()).reduce(
        (prev, key) => ({ ...prev, [key]: params.get(key) }),
        { ...query },
      );
    } else {
      queryParams = { ...query, ...params };
    }

    // eslint-disable-next-line no-param-reassign
    path = `${url}?${QueryString.stringify(queryParams)}`;
  }

  return callFetch(path, buildRequest('GET'));
}

const iOSDevices = ['iPhone', 'iPad', 'iPod'];

function isIOS() {
  if (!window.navigator || !window.navigator.platform) {
    return false;
  }

  return iOSDevices.some(
    (device) => window.navigator.platform.indexOf(device) !== -1,
  );
}

function post(path, data) {
  return callFetch(path, buildRequest('POST', data));
}

function put(path, data) {
  return callFetch(path, buildRequest('PUT', data));
}

function patch(path, data) {
  return callFetch(path, buildRequest('PATCH', data));
}

function httpDelete(path, data) {
  return callFetch(path, buildRequest('DELETE', data));
}

const Http = {
  delete: httpDelete,
  get,
  patch,
  post,
  put,
};

export { FormatResponse, isIOS, QueryString };

export default Http;
