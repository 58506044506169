/* eslint-disable max-classes-per-file */

import { ATTRIBUTE_TYPES } from 'lib/generated_constants/attributes_constants';
import {
  FILTERABLE_ACTIVITY_STATS,
  FILTERABLE_FIELDS,
  FILTERABLE_INCENTIVES,
} from 'lib/generated_constants/filters_constants';
import { RESERVED_ATTRIBUTE_NAMES } from 'lib/generated_constants/participant_population_attributes';

const VALID_ACTIVITY_STATS = new Set(Object.keys(FILTERABLE_ACTIVITY_STATS));
export const VALID_FIELDS = new Set(Object.keys(FILTERABLE_FIELDS));
const VALID_INCENTIVES = new Set(Object.keys(FILTERABLE_INCENTIVES));
export const VALID_TYPES = new Set(Object.values(ATTRIBUTE_TYPES));

export class ParticipantSearchColumn {
  constructor({ name, label = name, type } = {}) {
    this.activityStat = undefined;
    this.field = undefined;
    this.id = undefined;

    this.name = name;
    this.label = label;
    this.type = type;
  }

  get persisted() {
    return false;
  }

  get valid() {
    throw new Error('Invalid class');
  }

  matchesHeader() {
    return false;
  }

  json() {
    return {
      activityStat: this.activityStat,
      field: this.field,
      // should have named this participantPopulationAttributeId but now we have an async background
      // job looking for "id" so don't rename this
      id: this.id,
      name: this.name.trim(),
      type: this.type,
    };
  }

  update() {
    throw new Error('This class cannot be modified');
  }
}

export class ActivityStatSearchColumn extends ParticipantSearchColumn {
  constructor(activityStat) {
    super(FILTERABLE_ACTIVITY_STATS[activityStat]);
    this.activityStat = activityStat;
  }

  get valid() {
    return VALID_ACTIVITY_STATS.has(this.activityStat);
  }

  get persisted() {
    return false;
  }
}

export class FieldSearchColumn extends ParticipantSearchColumn {
  constructor(field) {
    super(FILTERABLE_FIELDS[field]);
    this.field = field;
    this.label = FILTERABLE_FIELDS[field]?.name
      ? `${FILTERABLE_FIELDS[field]?.name} (${field})`
      : field;
  }

  matchesHeader(field) {
    return !!(this.field?.toLowerCase() === field.toLowerCase());
  }

  get valid() {
    return VALID_FIELDS.has(this.field);
  }

  get persisted() {
    return this.valid;
  }
}

export class OptInFormSearchColumn extends ParticipantSearchColumn {
  constructor({ key, name = '', label = name } = {}) {
    super({ name, label });
    this.filterKey = key;
  }

  get valid() {
    return !!(this.filterKey && this.name);
  }

  get persisted() {
    return false;
  }
}
export class ProjectSearchColumn extends ParticipantSearchColumn {
  constructor({ key, name = '', label = name } = {}) {
    super({ name, label });
    this.filterKey = key;
  }

  get valid() {
    return !!(this.filterKey && this.name);
  }

  get persisted() {
    return false;
  }
}

export class IncentiveSearchColumn extends ParticipantSearchColumn {
  constructor(incentive) {
    super(FILTERABLE_INCENTIVES[incentive]);
    this.filterKey = incentive;
  }

  get valid() {
    return VALID_INCENTIVES.has(this.filterKey);
  }

  get persisted() {
    return false;
  }
}

export class PopulationAttributeSearchColumn extends ParticipantSearchColumn {
  constructor({ id, type, name = '', label = name } = {}) {
    super({
      name,
      label,
      type,
    });

    this.id = id;
  }

  get valid() {
    return !!(
      this.persisted ||
      (VALID_TYPES.has(this.type) && this.name && !this.nameIsReserved)
    );
  }

  get nameIsReserved() {
    return !!RESERVED_ATTRIBUTE_NAMES.includes(this.name.trim().toLowerCase());
  }

  get persisted() {
    return this.id > 0;
  }

  get participantPopulationAttributeId() {
    return this.id;
  }

  update({ name, type }) {
    if (this.persisted) {
      throw new Error('Persisted attributes cannot be modified');
    }

    if (name !== undefined) {
      this.name = name || '';
    }

    if (type) {
      if (!VALID_TYPES.has(type)) {
        throw new Error('Invalid population attribute type');
      }

      this.type = type;
    }
  }
}

export function compareColumns(a, b) {
  const lowerA = a.name.toLowerCase();
  const lowerB = b.name.toLowerCase();

  if (lowerA === 'id' && lowerB === 'id') {
    return 0;
  }

  if (lowerA === 'id') {
    return -1;
  }

  if (lowerB === 'id') {
    return 1;
  }

  return lowerA.localeCompare(lowerB, 'en', { sensitivity: 'base' });
}

export function initializeImportColumns(participantPopulationAttributes) {
  // Import columns are relevant population attributes and only select mapped fields

  return [
    ...Object.entries(FILTERABLE_FIELDS)
      .filter(([, value]) => Object(value).importable)
      .map(([key]) => new FieldSearchColumn(key)),
    ...participantPopulationAttributes.map(
      (attr) => new PopulationAttributeSearchColumn(attr),
    ),
  ].sort(compareColumns);
}
