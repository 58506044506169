// TODO: migrate functions to app/javascript/lib/formatters/typed_strings.ts here

export function charToSpaces(string, char = '_') {
  if (!string) return null;

  return string.replace(new RegExp(`${char}`, 'g'), ' ');
}

export function toSentenceCase(string, separator = '_') {
  if (!string) return null;

  const stringWithoutSeparator = charToSpaces(string, separator).toLowerCase();
  return stringWithoutSeparator.replace(/^\w/, (c) => c.toUpperCase());
}

export function toCamelCase(string) {
  if (!string) return null;

  return string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function toDashedCase(string) {
  if (!string) return null;

  return string.replace(/_/g, '-').toLowerCase();
}

export function toPascalCase(string) {
  if (!string) return null;

  return toCamelCase(string).replace(/./, string[0].toUpperCase());
}

export function toUnderscoreCase(string) {
  if (!string) return null;

  return string.replace(/([^_A-Z])(([A-Z])|-)/g, '$1_$3').toLowerCase();
}

export function initialsFromName(string) {
  if (string === null) return null;
  if (string.trim() === '') return '';

  return string
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
}

export function truncate(string, length, { ellipsis = false } = {}) {
  if (!string) return null;
  if (!length) return string;

  const trimmedString = string.trim();
  return `${trimmedString.slice(0, length).trim()}${ellipsis && trimmedString.length > length ? '...' : ''}`;
}

export function resourceNameFromResourceId(string) {
  if (!string) return null;

  return charToSpaces(string).replace(/ (id|ids)$/, '');
}

export function setValueAsInteger(value) {
  if (!value) return value;

  return parseInt(value);
}
