import { ErrorRenderer } from 'lib/errors';
import * as routes from 'lib/routes';
import Http from 'lib/http';

import {
  ProjectSessionMeetSchemeRegex,
  ProjectSessionTeamsSchemeRegex,
  ProjectSessionWebexSchemeRegex,
  ProjectSessionZoomSchemeRegex,
} from 'lib/generated_constants/project_location_details';

export const buildMeetSessionLocation = accountId => `meet://${accountId}`;
export const buildTeamsSessionLocation = accountId => `teams://${accountId}`;
export const buildWebexSessionLocation = accountId => `webex://${accountId}`;
export const buildZoomSessionLocation = accountId => `zoom://${accountId}`;

export const matchesMeetSessionLocation = (defaultSessionLocation) =>
  !!(defaultSessionLocation || '').match(ProjectSessionMeetSchemeRegex);
export const matchesTeamsSessionLocation = (defaultSessionLocation) =>
  !!(defaultSessionLocation || '').match(ProjectSessionTeamsSchemeRegex);
export const matchesWebexSessionLocation = defaultSessionLocation =>
  !!(defaultSessionLocation || '').match(ProjectSessionWebexSchemeRegex);
export const matchesZoomSessionLocation = defaultSessionLocation =>
  !!(defaultSessionLocation || '').match(ProjectSessionZoomSchemeRegex);

export const loadProjectSummary = async (projectId) => {
  try {
    const summaryPath = routes.api_project_summary_path({
      project_id: projectId,
    });

    const {
      data: { attributes },
    } = await Http.get(summaryPath);
    return attributes;
  } catch (error) {
    ErrorRenderer.alert(error, 'loading summaries');
    return error;
  }
};
