import { ParticipantStatuses } from 'lib/generated_constants/projects';

const STAFF_EDITABLE_PARTICIPANT_STATUSES = [
  ParticipantStatuses.APPROVED,
  ParticipantStatuses.CONFIRMED,
  ParticipantStatuses.INTERVIEW_COMPLETED,
  ParticipantStatuses.NO_SHOW,
  ParticipantStatuses.FRAUDULENT,
  ParticipantStatuses.PAID,
  ParticipantStatuses.PARTICIPANT_CANCELED,
  ParticipantStatuses.PARTICIPANT_RESCHEDULED,
  ParticipantStatuses.PREQUALIFIED,
  ParticipantStatuses.QUALIFIED,
  ParticipantStatuses.QUALIFIED_NEEDS_REVIEW,
  ParticipantStatuses.REJECTED,
  ParticipantStatuses.RESEARCHER_RESCHEDULED,
  ParticipantStatuses.UNQUALIFIED,
  ParticipantStatuses.UNSORTED,
];

const RESEARCHER_EDITABLE_PARTICIPANT_STATUSES = [
  ParticipantStatuses.APPROVED,
  ParticipantStatuses.CONFIRMED,
  ParticipantStatuses.PARTICIPANT_RESCHEDULED,
  ParticipantStatuses.PREQUALIFIED,
  ParticipantStatuses.QUALIFIED,
  ParticipantStatuses.QUALIFIED_NEEDS_REVIEW,
  ParticipantStatuses.RESEARCHER_RESCHEDULED,
  ParticipantStatuses.UNQUALIFIED,
  ParticipantStatuses.UNSORTED,
];

export const participantStatusAllowsIncentiveEditing = (
  participantStatus,
  canEditDistributedIncentives = false,
) => {
  if (!participantStatus) return false;

  const participantStatusId =
    typeof participantStatus === 'number'
      ? participantStatus
      : ParticipantStatuses[participantStatus?.toUpperCase()];

  if (canEditDistributedIncentives) {
    return (
      participantStatusId &&
      STAFF_EDITABLE_PARTICIPANT_STATUSES.includes(participantStatusId)
    );
  }

  return (
    participantStatusId &&
    RESEARCHER_EDITABLE_PARTICIPANT_STATUSES.includes(participantStatusId)
  );
};
