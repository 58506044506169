import { TeamSubscriptionFlags } from 'lib/generated_constants/subscriptions';

export default class SubscriptionService {
  // Utility
  static hasFlag(teamSubscription, flag) {
    return !!(teamSubscription && teamSubscription.features.includes(flag));
  }

  static hasAdvancedScreening(teamSubscription) {
    return this.hasFlag(
      teamSubscription,
      TeamSubscriptionFlags.ADVANCED_SCREENING,
    );
  }

  static hasOccupationTargeting(teamSubscription) {
    return this.hasFlag(
      teamSubscription,
      TeamSubscriptionFlags.OCCUPATION_TARGETING,
    );
  }

  static hasDocumentSigning(teamSubscription) {
    return this.hasFlag(
      teamSubscription,
      TeamSubscriptionFlags.DOCUMENT_SIGNING,
    );
  }

  static hasRecruitSubscription(teamSubscription) {
    return this.hasFlag(
      teamSubscription,
      TeamSubscriptionFlags.RECRUIT_UNLIMITED,
    );
  }

  static hasHubSubscription(teamSubscription) {
    return this.hasFlag(
      teamSubscription,
      TeamSubscriptionFlags.PRIVATE_POPULATION,
    );
  }

  static isLegacySubscription(teamSubscription) {
    return teamSubscription && teamSubscription.isLegacy;
  }

  static isLegacyHubSubscription(teamSubscription) {
    return teamSubscription && teamSubscription.isLegacyHub;
  }

  static isLegacyRecruitSubscription(teamSubscription) {
    return teamSubscription && teamSubscription.isLegacyRecruit;
  }
}
