import { COLORS } from '@user-interviews/ui-design-system';
import { ParticipantReviewTiers } from 'lib/generated_constants/projects';

const ParticipantReviewTierLabels = {
  [ParticipantReviewTiers.NOT_REVIEWED]: 'No rating',
  [ParticipantReviewTiers.GOOD]: 'Best',
  [ParticipantReviewTiers.OK]: 'Good',
  [ParticipantReviewTiers.BAD]: 'Poor',
};

const ParticipantReviewTierColors = {
  [ParticipantReviewTiers.NOT_REVIEWED]: {
    hover: COLORS.UX_GRAY_100,
    text: COLORS.UX_GRAY_600,
  },
  [ParticipantReviewTiers.GOOD]: {
    hover: COLORS.UX_GREEN_100,
    text: COLORS.UX_GREEN_600,
  },
  [ParticipantReviewTiers.OK]: {
    hover: COLORS.UX_ORANGE_100,
    text: COLORS.UX_ORANGE_600,
  },
  [ParticipantReviewTiers.BAD]: {
    hover: COLORS.UX_RED_100,
    text: COLORS.UX_RED_600,
  },
};

function getReviewTierId(reviewTier) {
  if (reviewTier === undefined || reviewTier == null) {
    return reviewTier;
  }

  if (Number.isInteger(reviewTier)) {
    return Object.values(ParticipantReviewTiers).find(
      (tierId) => tierId === reviewTier,
    );
  }

  return ParticipantReviewTiers[reviewTier.toUpperCase()];
}

function sortReviewTierOptions(a, b) {
  if (a.value === ParticipantReviewTiers.NOT_REVIEWED) {
    return -1;
  }
  if (b.value === ParticipantReviewTiers.NOT_REVIEWED) {
    return 1;
  }

  return b.value - a.value;
}

export {
  ParticipantReviewTiers,
  ParticipantReviewTierColors,
  ParticipantReviewTierLabels,
  getReviewTierId,
  sortReviewTierOptions,
};
