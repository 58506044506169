import { JsonApiDataStore } from 'jsonapi-datastore';
import { HttpError } from '../errors';
import Http from '../http';

export default class S3Service {
  // This method is deprecated in favor of `upload`.
  // The original version of this method did not support JSON API
  // responses from the server or passing what route you wanted to
  // upload to.
  //
  // Scratch that. This whole file is now deprecated.
  // Use the `useAmazonS3` hook instead.
  static async uploadFile(file, route, metadata) {
    return this.upload({ file, route, metadata });
  }

  static async upload({ file, route, metadata, jsonApiResponse = false }) {
    let presignedUrl;
    let fields;
    let objectKey;
    const body = new FormData();
    const response = await Http.post(route, { metadata });

    if (jsonApiResponse) {
      const videoUpload = new JsonApiDataStore().syncWithMeta(response);

      presignedUrl = videoUpload.meta.url;
      fields = videoUpload.meta.fields;
      objectKey = videoUpload.data.key;
    } else {
      presignedUrl = response.url;
      fields = response.fields;
      objectKey = response.fields.key;
    }

    Object.keys(fields).forEach((key) => {
      body.append(key, fields[key]);
    });

    body.append('file', file);

    const uploadResponse = await fetch(presignedUrl, {
      body,
      method: 'POST',
    });

    if (!uploadResponse.ok) {
      throw new HttpError({
        message: 'There was a problem uploading your file.',
        status: uploadResponse.status,
        statusText: uploadResponse.statusText,
      });
    }

    return objectKey;
  }
}
