import { recordsByTeamName, sortByStringKey } from 'lib/collections';
import * as routes from 'lib/routes';
import {
  CUSTOM_THEME_TYPE,
  PLAINTEXT_THEME_TYPE,
  UI_DEFAULT_THEME_TYPE,
} from 'lib/generated_constants/branding';
import {
  PLAIN_TEXT_EMAIL_THEME_ID,
  plainText,
  UI_EMERALD_EMAIL_THEME_ID,
  uiBlue,
} from './constants';

const defaultThemes = [
  { label: 'UI Emerald', value: UI_EMERALD_EMAIL_THEME_ID },
  { label: 'Plain Text', value: PLAIN_TEXT_EMAIL_THEME_ID },
];

export function updateDefaultEmailThemeState(
  teamSetting,
  setDefaultEmailTheme,
) {
  let currentDefaultTheme = uiBlue;
  if (teamSetting.defaultEmailThemeType === PLAINTEXT_THEME_TYPE) {
    currentDefaultTheme = { ...plainText };
  } else if (teamSetting.defaultEmailThemeType === UI_DEFAULT_THEME_TYPE) {
    currentDefaultTheme = { ...uiBlue };
  } else if (teamSetting.defaultEmailThemeType === CUSTOM_THEME_TYPE) {
    currentDefaultTheme = { ...teamSetting.defaultEmailTheme };
  }
  setDefaultEmailTheme(currentDefaultTheme);
}

export function isCustomTheme(theme) {
  return !defaultThemes.find((t) => t.value === theme.id);
}

export async function fetchDefaultEmailTheme(
  teamId,
  setDefaultEmailTheme,
  get,
) {
  const url = routes.api_team_settings_path({
    include: 'default_email_theme',
    team_id: teamId,
  });
  let teamSetting;
  try {
    teamSetting = await get(url);
  } catch (_e) {
    teamSetting = { defaultEmailThemeType: UI_DEFAULT_THEME_TYPE };
  }
  updateDefaultEmailThemeState(teamSetting, setDefaultEmailTheme);
}

export const emailThemeOptionReducer = (emailThemes, defaultEmailTheme) => {
  if (
    defaultEmailTheme &&
    ![PLAIN_TEXT_EMAIL_THEME_ID, UI_EMERALD_EMAIL_THEME_ID].includes(
      defaultEmailTheme.id,
    ) &&
    !defaultThemes.some((theme) => theme.value === Number(defaultEmailTheme.id))
  ) {
    defaultThemes.unshift({
      label: labelForTheme(defaultEmailTheme),
      value: Number.parseInt(defaultEmailTheme.id),
      isDiscarded: !!defaultEmailTheme?.discardedAt,
      name: defaultEmailTheme.name,
    });
  }

  const filteredEmailThemes = emailThemes.filter(
    (theme) => theme.id !== defaultEmailTheme?.id,
  );

  const groupedOptions = Object.entries(
    recordsByTeamName(filteredEmailThemes),
  ).reduce((acc, pair) => {
    const [teamName, themes] = pair;

    if (!acc[teamName]) {
      acc[teamName] = [];
    }

    const themeOptions = sortByStringKey(themes, 'name').map((theme) => ({
      label: labelForTheme(theme),
      value: Number.parseInt(theme.id),
      isDiscarded: !!theme?.discardedAt,
      name: theme.name,
    }));

    acc.push({ label: teamName, options: themeOptions });

    return acc;
  }, []);

  return [
    {
      label: 'defaults',
      options: defaultThemes,
    },
    ...sortByStringKey(groupedOptions, 'label'),
  ];
};

export const emailThemeOptionSelector = (
  emailThemeOptions,
  selectedThemeId,
  useEmailTheme,
) => {
  const valueToFind = useEmailTheme
    ? selectedThemeId || UI_EMERALD_EMAIL_THEME_ID
    : PLAIN_TEXT_EMAIL_THEME_ID;

  const flattened = emailThemeOptions.reduce((acc, optionGroup) => {
    acc.push(...optionGroup.options);

    return acc;
  }, []);

  return flattened.find((option) => option.value === valueToFind);
};

function labelForTheme(theme) {
  let label = theme.name;

  if (theme.discardedAt) {
    label = `${label}  ⚠️`;
  }

  return label;
}
