import { participantStatusAllowsIncentiveEditing } from './participant_incentive_editable_status_by_id';

export const incentiveIsEditable = (
  participantData,
  canEditDistributedIncentives,
) => {
  const incentiveExists = !!participantData.incentive;
  const incentiveIsDistributed = !!participantData.incentive?.isDistributed;
  const participantInEditableStatus = participantStatusAllowsIncentiveEditing(
    participantData.status,
    canEditDistributedIncentives,
  );

  return canEditDistributedIncentives
    ? incentiveExists && participantInEditableStatus
    : incentiveExists && participantInEditableStatus && !incentiveIsDistributed;
};
