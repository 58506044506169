export default function isValidISODate(dateString) {
  const parts = dateString.split('-');
  // We need a full date with all 3 parts
  if (parts.length !== 3) {
    return false;
  }

  const year = Number.parseInt(parts[0]);
  const month = Number.parseInt(parts[1]);
  const date = Number.parseInt(parts[2]);

  // If any values are not numbers, kick out (we can ignore 0 here because 0 is also invalid)
  if (!year || !month || !date) {
    return false;
  }

  // Month in dates is 0 indexed...
  const checkDate = new Date(year, month - 1, date);

  // Check that we didn't create an overflow because JS date parsing is bananas
  return (
    checkDate.getFullYear() === year &&
    checkDate.getMonth() === month - 1 &&
    checkDate.getDate() === date
  );
}
