// https://github.com/sindresorhus/query-string for other options not supported right now
import { Base64 } from 'js-base64';
import qs from 'query-string';

/*
 Note that `atob` and `btoa` were not previously handling UTF-8 encodings properly... instead
 converting special characters into assumed ASCII which then failed to parse right.

 https://github.com/dankogai/js-base64#decode-vs-atob-and-encode-vs-btoa
 */
function base64Decode(value, utf8 = true) {
  return utf8 ? Base64.decode(value) : Base64.atob(value);
}

function base64Encode(value, utf8 = true) {
  let valueToEncode = value;

  if (value instanceof Object) {
    valueToEncode = JSON.stringify(value);
  }

  return utf8 ? Base64.encode(valueToEncode, true) : Base64.btoa(valueToEncode);
}

// Removes a single key from the querystring
function clearKey(key) {
  const urlObj = new URL(window.location.href);
  const params = new URLSearchParams(urlObj.search);
  params.delete(key);
  urlObj.search = params.toString();
  const updatedURL = urlObj.href;
  window.history.replaceState(null, '', updatedURL);
}

const QueryString = {
  base64Decode,
  base64Encode,
  clearKey,
  extract: (str) => qs.extract(str),
  parse: (str) => qs.parse(str, { arrayFormat: 'bracket' }),
  parseUrl: (str) => qs.parseUrl(str, { arrayFormat: 'bracket' }),
  stringify: (obj, opts = {}) =>
    qs.stringify(obj, { arrayFormat: 'bracket', ...opts }),
};

export default QueryString;
