import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Keep this eslint-disable here. FontAwesome imports should only be happening
// in this file and nowhere else in the codebase.
//
// Any direct import from '@fortawesome/pro-regular-svg-icons' will create bloat in
// our overall bundle size.

/* eslint-disable no-restricted-imports */

import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons/faArrowAltCircleLeft';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faBellSlash } from '@fortawesome/pro-solid-svg-icons/faBellSlash';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons/faBookOpen';
import { faBookReader } from '@fortawesome/pro-solid-svg-icons/faBookReader';
import { faBoxesStacked } from '@fortawesome/pro-solid-svg-icons/faBoxesStacked';
import { faBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase';
import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons/faBullseyeArrow';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons/faCalendarCheck';
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons/faCalendarDays';
import { faCalendarPlus } from '@fortawesome/pro-solid-svg-icons/faCalendarPlus';
import { faCalendarTimes } from '@fortawesome/pro-solid-svg-icons/faCalendarTimes';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faCircleHalfStroke } from '@fortawesome/pro-solid-svg-icons/faCircleHalfStroke';
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons/faClipboardList';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCloudUploadAlt } from '@fortawesome/pro-solid-svg-icons/faCloudUploadAlt';
import { faCodeBranch } from '@fortawesome/pro-solid-svg-icons/faCodeBranch';
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons/faCommentAlt';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faDoNotEnter } from '@fortawesome/pro-solid-svg-icons/faDoNotEnter';
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons/faDotCircle';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faEnvelopeSquare } from '@fortawesome/pro-solid-svg-icons/faEnvelopeSquare';
import { faExchangeAlt } from '@fortawesome/pro-solid-svg-icons/faExchangeAlt';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faFileImage } from '@fortawesome/pro-solid-svg-icons/faFileImage';
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons/faFileUpload';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons/faFloppyDisk';
import { faFolder } from '@fortawesome/pro-solid-svg-icons/faFolder';
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons/faGripDotsVertical';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake';
import { faHashtag } from '@fortawesome/pro-solid-svg-icons/faHashtag';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faInbox } from '@fortawesome/pro-solid-svg-icons/faInbox';
import { faInfinity } from '@fortawesome/pro-solid-svg-icons/faInfinity';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faLaptop } from '@fortawesome/pro-solid-svg-icons/faLaptop';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faListCheck } from '@fortawesome/pro-solid-svg-icons/faListCheck';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faMagic } from '@fortawesome/pro-solid-svg-icons/faMagic';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faMicrophone } from '@fortawesome/pro-solid-svg-icons/faMicrophone';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle';
import { faMinusSquare } from '@fortawesome/pro-solid-svg-icons/faMinusSquare';
import { faNoteSticky } from '@fortawesome/pro-solid-svg-icons/faNoteSticky';
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faReply } from '@fortawesome/pro-solid-svg-icons/faReply';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons/faRepeat';
import { faRobot } from '@fortawesome/pro-solid-svg-icons/faRobot';
import { faRotate } from '@fortawesome/pro-solid-svg-icons/faRotate';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import { faSortNumericDown } from '@fortawesome/pro-solid-svg-icons/faSortNumericDown';
import { faSortNumericUp } from '@fortawesome/pro-solid-svg-icons/faSortNumericUp';
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { faSquareFull } from '@fortawesome/pro-solid-svg-icons/faSquareFull';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faTasks } from '@fortawesome/pro-solid-svg-icons/faTasks';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faTv } from '@fortawesome/pro-solid-svg-icons/faTv';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUserCheck } from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import { faUserClock } from '@fortawesome/pro-solid-svg-icons/faUserClock';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';
import { faUserTimes } from '@fortawesome/pro-solid-svg-icons/faUserTimes';
import { faVideo } from '@fortawesome/pro-solid-svg-icons/faVideo';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

library.add(faAngleDown);
library.add(faAngleUp);
library.add(faArrowAltCircleLeft);
library.add(faArrowDown);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faArrowUp);
library.add(faBars);
library.add(faBan);
library.add(faBell);
library.add(faBellSlash);
library.add(faBookOpen);
library.add(faDownload);
library.add(faCalendarAlt);
library.add(faCaretDown);
library.add(faCaretUp);
library.add(faCheck);
library.add(faCircleCheck);
library.add(faChevronDown);
library.add(faClipboardList);
library.add(faClock);
library.add(faComments);
library.add(faDollarSign);
library.add(faEdit);
library.add(faEnvelopeSquare);
library.add(faExchangeAlt);
library.add(faExclamation);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faExternalLinkAlt);
library.add(faEye);
library.add(faEyeSlash);
library.add(faFilter);
library.add(faFloppyDisk);
library.add(faFolder);
library.add(faGift);
library.add(faGlobe);
library.add(faGripDotsVertical);
library.add(faGripVertical);
library.add(faHandshake);
library.add(faHashtag);
library.add(faHome);
library.add(faInbox);
library.add(faInfinity);
library.add(faKey);
library.add(faLock);
library.add(faMapMarkerAlt);
library.add(faMicrophone);
library.add(faMinus);
library.add(faMinusCircle);
library.add(faPalette);
library.add(faPencil);
library.add(faPhone);
library.add(faPlus);
library.add(faPlusCircle);
library.add(faQuestion);
library.add(faQuestionCircle);
library.add(faReply);
library.add(faRepeat);
library.add(faShare);
library.add(faSortDown);
library.add(faSortUp);
library.add(faSpinner);
library.add(faStar);
library.add(faSync);
library.add(faTag);
library.add(faTasks);
library.add(faTimesCircle);
library.add(faTrash);
library.add(faUpload);
library.add(faUser);
library.add(faUserPlus);
library.add(faUsers);
library.add(faXmark);

export {
  faAddressBook,
  faAddressCard,
  faAngleDown,
  faAngleUp,
  faArrowAltCircleLeft,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBell,
  faBellSlash,
  faBookOpen,
  faBookReader,
  faBookmark,
  faBoxesStacked,
  faBullseyeArrow,
  faBriefcase,
  faCaretDown,
  faCaretUp,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDays,
  faCalendarPlus,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faCircle,
  faCircleCheck,
  faCircleHalfStroke,
  faClipboardList,
  faClock,
  faCloudUploadAlt,
  faCodeBranch,
  faComment,
  faCommentAlt,
  faCopy,
  faCreditCard,
  faDollarSign,
  faDoNotEnter,
  faDotCircle,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeSquare,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImage,
  faFileUpload,
  faFilter,
  faFlag,
  faFloppyDisk,
  faFolder,
  faFont,
  faGear,
  faGift,
  faGlobe,
  faGripDotsVertical,
  faGripVertical,
  faHandshake,
  faHashtag,
  faHome,
  faInbox,
  faInfinity,
  faKey,
  faLaptop,
  faList,
  faListCheck,
  faLock,
  faMagic,
  faMapMarkerAlt,
  faMicrophone,
  faMinus,
  faMinusCircle,
  faMinusSquare,
  faNoteSticky,
  faPalette,
  faPaperPlane,
  faPen,
  faPencil,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faReply,
  faRepeat,
  faRobot,
  faRotate,
  faSearch,
  faShare,
  faShieldCheck,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortDown,
  faSortNumericDown,
  faSortNumericUp,
  faSpinner,
  faSpinnerThird,
  faSquareFull,
  faStar,
  faSync,
  faSyncAlt,
  faTag,
  faTasks,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTv,
  faUpload,
  faUser,
  faUserCheck,
  faUserClock,
  faUserPlus,
  faUsers,
  faUserTie,
  faUserTimes,
  faVideo,
  faXmark,
};

// This will make it so we automatically check the DOM for updates which allows
// us to use CSS selectors to create icons outside of React.
dom.watch();
